/** @type {import('tailwindcss').Config} */
export default {
  content: [
    './src/**/*.{js,ts,jsx,tsx}',
    './src/components/**/*.{js,ts,jsx,tsx,scss,css}',
  ],
  theme: {
    extend: {
      screens: {
        xs: '376px',
        sm430: '431px',
      },
      colors: {
        black: '#000000',
        midnight: '#051336',
        twilight: '#202b7e',
        blue: '#005bff',
        green: '#25c955',
        yellow: '#ffc165',
        red: '#e85757',
        slate: '#1d1d1d',
        gray: {
          DEFAULT: '#757575',
          dark: '#757575',
          light: '#f4f4f4',
          xlight: '$d9d9d9',
        },
        white: '#ffffff',
        warning: '#E85757',
        info: '#202b7e',
        fieldBorder: 'rgb(189, 189, 189)',
      },
      borderRadius: {
        0: '2px',
        1: '4px',
        2: '8px',
        3: '16px',
        4: '24px',
        5: '32px',
        16: '48px',
      },
      spacing: {
        1: '0.25rem',
        2: '0.5rem',
        3: '0.75rem',
        4: '1rem',
      },
      fontFamily: {
        sans: '"Montserrat", Helvetica, Arial, apple-system, sans-serif',
      },
      fontSize: {
        '3xs': '0.5rem', // 8px
        '2xs': '0.625rem', // 10px
        xs: '0.75rem', // 12px
        sm: '0.875rem', // 14px
        body: '1em', // 16px
        lg: '1.25rem', // 20px
        xl: '1.5rem', // 24px
        '2xl': '2.125rem', // 34px
        '3xl': '3rem', // 48px
        '4xl': '3.75rem', // 60px
        '5xl': '6rem', // 96px
      },
      lineHeight: {
        0: '0',
        1: '1', // 16px
        2: '1.12rem', // 18px
        3: '1.25rem', // 20px
        4: '1.5rem', // 24px
        8: '2rem', // 32px
        10: '2.5rem', // 40px
      },
    },
  },
  plugins: [],
}
